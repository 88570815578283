<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 d-flex">
        <div class="wrapper w-100 m-auto pb-5">
          <div class="px-4 mb-5">
            <p class="titulo-pag">Atores</p>
            <h1 class="mt-3">Cadastrar ator</h1>
          </div>
          <div class="text-center">
            <button class="btn btn-primary mx-1 mb-5 px-4 py-2 rounded-pill" type="button" @click="tipo = 'fisico'" :disabled="tipo === 'fisico'">
              Pessoa física
            </button>
            <button class="btn btn-primary mx-1 mb-5 px-4 py-2 rounded-pill" type="button" @click="tipo = 'juridico'" :disabled="tipo === 'juridico'">
              Pessoa Jurídica
            </button>
          </div>

          <FormAtorFisico v-if="tipo === 'fisico'" />
          <FormAtorJuridico v-if="tipo === 'juridico'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormAtorFisico from './FormAtorFisico.vue';
import FormAtorJuridico from './FormAtorJuridico.vue';

export default {
  name: 'AtoresCadastro',
  data () {
    return {
      tipo: 'fisico'
    };
  },
  components: {
    FormAtorFisico,
    FormAtorJuridico
  }
};

</script>
<style scoped>
  .titulo-pag{
    font-size: 14px;
    font-weight:600;
    color: #0A85CA;
  }
  h1{
    font-size: 22px;
    font-weight: bolder;
  }
  h1+span{
    font-size: 14px;
    color: #717171;
  }
</style>
